<template>
    <div class="hcell" style="width: 320px">
        <div class="blockgl_tdd">
            <div class="blockgl_tdimg" v-if="bimg">
                <img  :src="bimg">
            </div>
            <div class="blockgl_tddh" v-html="bhead"></div>
            <div class="blockgl_tddt" v-html="btext"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "gn_m_cell",
        props: {
            bimg: {
                type: String,
            },
            bhead: {
                type: String,
            },
            btext: {
                type: String,
            },
        },
    }
</script>

<style scoped>
    .blockgl_tdimg {
        height: 50px;
        align-items: center;
        display: flex;
        /*border: 1px solid red;*/
    }

    .blockgl_tdd{
        width: 320px;
       /*border: 1px solid red;*/
    }
    .blockgl_tddh{
        font-size: 18px;
        font-weight: bold;
        color: rgba(20,20,20,0.9);
        margin-top: 30px;
    }
    .blockgl_tddt{
        font-size: 16px;
        color: rgba(20,20,20,0.8);
        margin-top: 20px;
        line-height: 20px;
    }
</style>
