<template>
    <div class="htable"   style="justify-content: space-between;">
        <div class="hcell"><div class="block3 " :style="'background-image: url('+hrow[0].tb_image+');'"> </div></div>
        <div class="hcell"><div class="block3 " :style="'background-image: url('+hrow[1].tb_image+');'"> </div></div>
        <div class="hcell"><div class="block3 " :style="'background-image: url('+hrow[2].tb_image+');'"> </div></div>
    </div>
</template>

<script>
    export default {
        name: "imgblock_three",
        props: {
            hrow: {
                type: Array,
                required: true,
            },
        }
    }
</script>

<style scoped>
    .block3{
        width: 374px;
        height: 270px;
        border-radius: 10px;
    }
    .hic_gl31{background-image: url('~assets/hic_gl31.jpg');}
</style>
