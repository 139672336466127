<template>
    <div class="htable blockgl_row"   >
        <gnmcell v-if="brow[0]" :bimg="brow[0].tb_image" :bhead="brow[0].tb_title" :btext="brow[0].tb_summary" />
        <gnmcell v-if="brow[1]" :bimg="brow[1].tb_image" :bhead="brow[1].tb_title" :btext="brow[1].tb_summary" />
        <gnmcell v-if="brow[2]" :bimg="brow[2].tb_image" :bhead="brow[2].tb_title" :btext="brow[2].tb_summary" />
        <gnmcell v-else   :bhead="hnull" :btext="hnull" />
    </div>
</template>

<script>
    import gnmcell from "./gn_m_cell.vue"
    export default {
        name: "gn_m_row",
        props: {
            brow: {
                type: Array,
                required: true,
            },
        },
        data () {
            return {
                hnull:'',
            }
        },
        components: {
            gnmcell,
        }
    }
</script>

<style scoped>
    .blockgl_row {
        justify-content: space-between;
        /*border: 1px solid red;*/
    }

</style>
