<template>
    <div class="hsctemplate">
        <section class="body1200" style="margin-top: 90px;">
            <div class="txt60" style="margin-top: 30px">HSC智慧市政</div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                太阳能智慧照明解决方案通过路灯智能管理平台和路灯智能控制器，对路灯、景观灯、草坪灯、宣传栏等公共照明实行统一管理，达到照明远程监测、智能管控、节省能耗、降低运维费用的智能化管理成效。
            </div>
        </section>
        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                整体解决方案
            </div>

            <div class="txt18" style="margin-top: 30px;width:860px;">
                HSC智慧云平台系统，提供共享运维、委托运维等模式；通过终端控制器及物联网实现故障自动检测，并提供产品运维配置优化方案；通过前端设备的先进技术为客户实现有效照明、延长设备使用寿命、降低配置成本及维护成本等。
            </div>
            <div class="hsc_fa1" style="margin-top: 60px;">
            </div>
        </section>

        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                特色功能
            </div>
            <imgblock_three :hrow="imgblock_three1"  style="margin-top: 60px; " />



            <gnmrow :brow="gnmrow11"  style="margin-top: 60px;"> </gnmrow>
            <gnmrow :brow="gnmrow12"  style="margin-top: 60px;"> </gnmrow>
        </section>

        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                方案架构
            </div>
            <div class="hsc_fa2" style="margin-top: 60px;">
            </div>
        </section>
        <section class="body1200" style="margin-top: 160px;">
            <div  class="txt42">
                智慧云平台
            </div>
            <div class="txt18" style="margin-top: 30px;width:860px;">
                辉腾HEC云平台是一套基于云计算的物联网云应用平台。支持用户组织架构管理、大数据分析、API接口对接、业务平台定制等丰富的功能。提供从终端设备、协议转换、数据采集分析到基于WEB和APP应用的功能需求整体实现。可根据用户的实际需求，基于标准化逻辑进行定制，帮助电站提高发电量，增加经济收益。

            </div>
            <div :style="'margin-top: 60px;height: 675px;background-image: url('+ hsc_dp +');'"   >
            </div>
        </section>


        <section class="body1200" style="margin-top: 160px; ">
            <div style="font-size: 42px;">
                方案优势
            </div>
            <div class="txtzw" style="width: 860px;margin-top: 60px;">
                辉腾光伏能源通过将信息技术、互联网技术与光伏技术跨界融合，集成高效光伏发电，高压储能，实现可视化家庭能源管理，场景化、简单化一键用电，为客户带来更安全、多发电、好管理的美好生活体验。
            </div>
            <div style="margin-top: 56px;">
                <img :src="hsc_ys">
            </div>
        </section>

        <section class="body1200" style="margin-top: 160px; ">
            <div style="font-size: 42px;">
                相关案例
            </div>
            <al_three :hrow="alrows" />
        </section>



    </div>

</template>
<script>
    import imgblock_three from "./control/imgblock_three.vue"
    import gnmrow from "./control/gn_m_row.vue"
    import al_three from "./control/al_three.vue"
    export default {
        data () {
            return {
                hscgn: 1,

                imgblock_three1:[
                    {tb_image:require('../assets/hsc_gn1.jpg')},
                    {tb_image:require('../assets/hsc_gn2.jpg')},
                    {tb_image:require('../assets/hsc_gn3.jpg')},
                ],
                gnmrow11:[
                    {tb_image:require('../assets/hsc_gn4.png'),tb_title:'数据采集',tb_summary:'路灯上的终端设备采集路灯相关运行数据，通过物联网上传到云平台；同时接收云端控制指令。'},
                    {tb_image:require('../assets/hsc_gn5.png'),tb_title:'GIS地图监控',tb_summary:'采集路灯经纬度坐标，布局到一张地图上，只管反应路灯运行状态，根据地图上的路灯点进行颜色告警。'},
                    {tb_image:require('../assets/hsc_gn6.png'),tb_title:'故障报警',tb_summary:'故障告警，通过系统统计表展现，或通过邮件、微信、短信推送。'},
                ],
                gnmrow12:[
                    {tb_image:require('../assets/hsc_gn7.png'),tb_title:'远程控制',tb_summary:'通过APP或电脑远程控制路灯，可进行及时开关控制。'},
                    {tb_image:require('../assets/hsc_gn8.png'),tb_title:'养护策略',tb_summary:''},
                    {tb_image:require('../assets/hsc_gn9.png'),tb_title:'资产管理',tb_summary:''},
                ],


                hsc_dp:require('../assets/hsc_dp.jpg'),

                alrows:[
                    {tb_image:require('../assets/hec_al1.jpg'),tb_title:'四川阿坝1MW扶贫光伏项目'},
                    {tb_image:require('../assets/hec_al2.jpg'),tb_title:'四川理塘6MW扶贫光伏项目'},
                    {tb_image:require('../assets/hec_al3.jpg'),tb_title:'重庆巫溪整县扶贫光伏项目'},
                ],
                hsc_ys: require('../assets/hsc_ys.png'),
                address: require('../assets/address.png'),

            }
        },
        components: {
            gnmrow,
            imgblock_three,
            al_three,
        },
        mounted() {
            this.$parent.hscbanner()
        },
        methods: {

        },
        watch: {

        },
    }
</script>
<style lang="css" scoped>
    .hsctemplate{
        color: #202020;
        text-align: left;
    }
    .txt18{
        font-size: 18px;
        color:rgba(20,20,20,0.8);
        line-height: 32px;
    }
    .txt42{
        font-size: 42px;
        color:rgba(20,20,20,1);
        line-height: 42px;
    }
    .txt60{
        font-size: 60px;
        color:rgba(20,20,20,1);
        line-height: 60px;
    }

    .farm_ghblock{
        margin-top: 60px;
        /*height: 940px;*/
        /*border: 1px solid red;*/
    }
    .txtH16{
        font-size: 16px;
        color:rgba(20,20,20,0.8);
        line-height: 80px;
    }
    .hsc_fa1{
        background-image: url('~assets/hsc_jg1.jpg');
        width: 859px;
        height: 357px;
    }
    .hsc_fa2{
        background-image: url('~assets/hsc_jg2.jpg');
        height: 614px;
    }
    .ghblockclick{
        cursor:pointer;
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 28px;
    }

    .block3{
        width: 374px;
        height: 270px;
        border-radius: 10px;
    }



    .hscgl_tdd{
        width: 320px;

        /*border: 1px solid red;*/
    }
    .hscgl_tddh{
        font-size: 18px;
        font-weight: bold;
        color: rgba(20,20,20,0.9);
        margin-top: 30px;
    }
    .hscgl_tddt{
        font-size: 16px;
        color: rgba(20,20,20,0.8);
        margin-top: 20px;
        line-height: 20px;
    }

</style>
