<template>
    <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
        <div class="hcell"><div class="hec_al hec_al1" :style="'background-image: url('+hrow[0].tb_image+');'"><div class="hec_albg1" ><div class="altxt1">
            <div><img :src="address"></div>
            <div v-html="hrow[0].tb_title"></div>
        </div> </div></div></div>
        <div class="hcell"><div class="hec_al hec_al2" :style="'background-image: url('+hrow[1].tb_image+');'" ><div class="hec_albg2" ><div class="altxt2">
            <div><img :src="address"></div>
            <div v-html="hrow[1].tb_title"></div>
        </div> </div></div></div>
        <div class="hcell"><div class="hec_al hec_al3" :style="'background-image: url('+hrow[2].tb_image+');'"> <div class="hec_albg3" ><div class="altxt3">
            <div><img :src="address"></div>
            <div v-html="hrow[2].tb_title"></div>
        </div> </div></div></div>
    </div>
</template>

<script>
    export default {
        name: "an_three",
        props: {
            hrow: {
                type: Array,
                required: true,
            },
        },
        data(){
            return {
                address: require('../../assets/address.png'),
            }
        }
    }
</script>

<style scoped>

    .hec_al{
        width: 374px;
        height: 270px;
        border-radius: 10px;
    }
    .altxt1{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg1{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt2{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg2{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt3{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg3{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .hec_al1:hover  .hec_albg1 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al1:hover .altxt1{
        display: inline;
    }

    .hec_al2:hover  .hec_albg2 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al2:hover .altxt2{
        display: inline;
    }
    .hec_al3:hover  .hec_albg3 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al3:hover .altxt3{
        display: inline;
    }
</style>
